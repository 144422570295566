<template>
  <b-modal
    id="modalCreateEditProject"
    :title="`${textModal} Projeto`"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Nome: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.project.name.$error }"
            placeholder="Nome"
            v-model="$v.project.name.$model"
          />
          <span v-if="!$v.project.name.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
          <span v-if="!$v.project.name.minLength" class="invalid-feedback">
            O nome deve possuir pelo menos
            {{ $v.project.name.$params.minLength.min }} caracteres
          </span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Descrição: </label>
          <textarea
            class="form-control"
            rows="5"
            placeholder="Descrição"
            v-model="project.description"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Valor: <span class="text-danger">*</span></label>
          <input
            type="number"
            class="form-control"
            step="0.01"
            :class="{ 'is-invalid': $v.project.value.$error }"
            v-model.number="$v.project.value.$model"
            placeholder="Ex: 200 | 350,55"
          />
          <span v-if="!$v.project.value.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>Status: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.project.status.$error }"
            v-model="$v.project.status.$model"
          >
            <option value="">Selecione o status</option>
            <option value="0">Reprovado</option>
            <option value="1">Aguardando Aprovação</option>
            <option value="2">Em Andamento</option>
            <option value="3">Concluído</option>
          </select>
          <span v-if="!$v.project.status.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          :title="textModal"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>{{ textModal }}</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'CreateEditProjectModal',

  props: {
    clientId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      project: {
        clientId: this.clientId,
        name: '',
        description: '',
        value: '',
        status: '',
      },
      disableButton: false,
    }
  },

  computed: {
    textModal() {
      return this.projectId ? 'Atualizar' : 'Cadastrar'
    },
  },

  methods: {
    async getProject() {
      try {
        this.project = await this.$store.dispatch('getProject', {
          clientId: this.clientId,
          projectId: this.projectId,
        })
        this.project = { ...this.project, value: Number(this.project.value) }
        this.$bvModal.show('modalCreateEditProject')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.projectId) {
            response = await this.$store.dispatch('updateProject', {
              clientId: this.clientId,
              projectId: this.projectId,
              project: this.project,
            })
          } else {
            response = await this.$store.dispatch('saveProject', {
              clientId: this.clientId,
              project: this.project,
            })
          }
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    project: {
      name: {
        required,
        minLength: minLength(3),
      },
      value: {
        required,
      },
      status: {
        required,
      },
    },
  },

  mounted() {
    if (this.projectId) {
      this.getProject()
    } else {
      this.$bvModal.show('modalCreateEditProject')
    }
  },
}
</script>

<template>
  <b-modal
    id="modalDetailsProject"
    title="Detalhes do Projeto"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal')"
  >
    <div class="row">
      <div class="col-sm-12">
        <p><strong>Nome: </strong>{{ project.name }}</p>
      </div>
      <div class="col-sm-12">
        <p><strong>Descrição: </strong>{{ project.description }}</p>
      </div>
      <div class="col-sm-12">
        <p><strong>Valor: </strong>{{ moneyToCurrencyBr(project.value) }}</p>
      </div>
      <div class="col-sm-12">
        <p>
          <strong>Status: </strong>
          <span v-html="statusProjectTranslate(project.status)"></span>
        </p>
      </div>
      <div class="col-sm-12">
        <p>
          <strong>Criado por:</strong>
          {{ createdUpdatedUser(project.createdBy, project.createdAt) }}
        </p>
      </div>
      <div class="col-sm-12">
        <p>
          <strong>Atualizado por:</strong>
          {{ createdUpdatedUser(project.updatedBy, project.updatedAt) }}
        </p>
      </div>
    </div>
    <div class="modal-footer-custom">
      <button
        type="button"
        class="btn btn-secondary"
        title="Fechar"
        @click="$emit('closeModal')"
      >
        Fechar
      </button>
    </div>
  </b-modal>
</template>

<script>
import {
  createdUpdatedUser,
  statusProjectTranslate,
  moneyToCurrencyBr,
} from '@/utils/strings'

export default {
  name: 'CreateEditProjectModal',

  props: {
    clientId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      project: {},
      createdUpdatedUser,
      statusProjectTranslate,
      moneyToCurrencyBr,
    }
  },

  methods: {
    async getProject() {
      try {
        this.project = await this.$store.dispatch('getProject', {
          clientId: this.clientId,
          projectId: this.projectId,
        })
        this.$bvModal.show('modalDetailsProject')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },
  },

  mounted() {
    this.getProject()
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <DetailsClient :clientId="clientId" />
    <ContratosClient :clientId="clientId" />
    <ProjectsClient :clientId="clientId" />
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import DetailsClient from '@/components/backoffice/clients/DetailsClient.vue'
import ProjectsClient from '@/components/backoffice/projects/ProjectsClient.vue'
import ContratosClient from '@/components/backoffice/contratos/ContratosClient.vue'

export default {
  name: 'ClientDetails',

  components: {
    Layout,
    PageHeader,
    DetailsClient,
    ProjectsClient,
    ContratosClient,
  },

  data() {
    return {
      clientId: this.$route.params.clientId,
      title: 'Detalhes do Cliente',
      items: [
        {
          text: 'BackOffice',
        },
        {
          text: 'Clientes',
          to: '/backoffice/clients',
        },
        {
          text: 'Detalhes',
          active: true,
        },
      ],
    }
  },
}
</script>

<template>
  <div>
    <CreateEditContratoModal
      v-if="showCreateEditContratoModal"
      :clientId="clientId"
      :contratoId="contratoIdSelected"
      @closeModal="closeCreateEditContratoModal"
    />
    <DetalhesContratoModal
      v-if="showDetailsContratoModal"
      :clientId="clientId"
      :contratoId="contratoIdSelected"
      @closeModal="closeDetailsContratoModal"
    />
    <div v-if="!loadData" class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Contratos</h5>
        <div v-if="getModuleUserByCode('1.01.002')">
          <button
            type="button"
            class="btn btn-primary"
            title="Novo Contrato"
            @click="createEditContrato()"
          >
            Novo Contrato
          </button>
        </div>
      </div>
      <div class="card-body">
        <ListContratos
          :contratos="contratos"
          @editContrato="createEditContrato"
          @detalhesContrato="detalhesContrato"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ListContratos from '@/components/backoffice/contratos/ListContratos.vue'
import DetalhesContratoModal from '@/components/backoffice/contratos/DetalhesContratoModal.vue'
import CreateEditContratoModal from '@/components/backoffice/contratos/CreateEditContratoModal.vue'

export default {
  name: 'ContratosClient',

  components: {
    CreateEditContratoModal,
    DetalhesContratoModal,
    ListContratos,
  },

  props: {
    clientId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loadData: true,
      showCreateEditContratoModal: false,
      showDetailsContratoModal: false,
      contratos: null,
      contratoIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getContratos() {
      try {
        this.contratos = await this.$store.dispatch('getContratos', {
          clientId: this.clientId,
        })
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    createEditContrato(contratoId) {
      this.contratoIdSelected = contratoId || null
      this.showCreateEditContratoModal = true
    },

    closeCreateEditContratoModal(loadListContratos) {
      if (loadListContratos) {
        this.getContratos()
      }
      this.showCreateEditContratoModal = false
    },

    detalhesContrato(contratoId) {
      this.contratoIdSelected = contratoId
      this.showDetailsContratoModal = true
    },

    closeDetailsContratoModal() {
      this.showDetailsContratoModal = false
    },
  },

  mounted() {
    this.getContratos()
  },
}
</script>

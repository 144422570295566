<template>
  <b-modal
    id="modalCreateEditContrato"
    size="xl"
    :title="`${textModal} Contrato`"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <div class="col-lg-12">
        <b-tabs
          justified
          nav-class="nav-tabs-custom"
          content-class="p-3 text-muted"
        >
          <b-tab active>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Dados do Contrato</span>
            </template>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>Titulo: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.contrato.titulo.$error }"
                  placeholder="Título do contrato"
                  v-model="$v.contrato.titulo.$model"
                />
                <span
                  v-if="!$v.contrato.titulo.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group col-sm-3">
                <label
                  >Data de início: <span class="text-danger">*</span></label
                >
                <input
                  type="date"
                  class="form-control"
                  :class="{ 'is-invalid': $v.contrato.data_inicio.$error }"
                  placeholder="Data de vencimento"
                  v-model="$v.contrato.data_inicio.$model"
                />
                <span
                  v-if="!$v.contrato.data_inicio.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group col-sm-3">
                <label>Data final: <span class="text-danger">*</span></label>
                <input
                  type="date"
                  class="form-control"
                  :class="{ 'is-invalid': $v.contrato.data_final.$error }"
                  placeholder="Data de nascimento"
                  v-model="$v.contrato.data_final.$model"
                />
                <span
                  v-if="!$v.contrato.data_final.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-3">
                <label>Valor: <span class="text-danger">*</span></label>
                <div class="input-group">
                  <span
                    class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                  >
                    <span class="input-group-text">R$</span></span
                  >
                  <input
                    type="number"
                    class="form-control"
                    step="0.01"
                    :class="{ 'is-invalid': $v.contrato.valor_contrato.$error }"
                    v-model.number="$v.contrato.valor_contrato.$model"
                    placeholder="000,00"
                    @blur="formatarValor"
                  />
                </div>
                <span
                  v-if="!$v.contrato.valor_contrato.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group col-sm-3">
                <label
                  >Condição de Pagamento:
                  <span class="text-danger">*</span></label
                >
                <select
                  class="form-control"
                  :class="{
                    'is-invalid': $v.contrato.condicao_pagamento.$error,
                  }"
                  v-model="$v.contrato.condicao_pagamento.$model"
                  @blur="condicaoPagamento"
                >
                  <option value="">Selecione a condição</option>
                  <option value="1">À Vista</option>
                  <option value="2">Parcelado</option>
                </select>
                <span
                  v-if="!$v.contrato.condicao_pagamento.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div
                v-if="contrato.condicao_pagamento == 2"
                class="form-group col-sm-3"
              >
                <label>Nº Parcelas: <span class="text-danger">*</span></label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="00"
                  v-model="contrato.parcelas_pagamento"
                  @blur="parcelamento"
                />
              </div>
              <div
                class="form-group col-sm-3"
                v-if="contrato.condicao_pagamento > 0"
              >
                <label v-if="contrato.condicao_pagamento == 2"
                  >Data da 1ª parcela: <span class="text-danger">*</span></label
                >
                <label v-if="contrato.condicao_pagamento == 1"
                  >Data de vencimento: <span class="text-danger">*</span></label
                >
                <input
                  type="date"
                  class="form-control"
                  :class="{
                    'is-invalid': $v.contrato.data_vencimento_parcela.$error,
                  }"
                  placeholder="Data de nascimento"
                  v-model="$v.contrato.data_vencimento_parcela.$model"
                  @blur="parcelamento"
                />
                <span
                  v-if="!$v.contrato.data_vencimento_parcela.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-3">
                <p>Reajuste: <span class="text-danger">*</span></p>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="statusInativo"
                    value="0"
                    v-model="contrato.reajuste"
                  />
                  <label class="form-check-label" for="statusInativo">
                    Não
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="statusAtivo"
                    value="1"
                    v-model="contrato.reajuste"
                  />
                  <label class="form-check-label" for="statusAtivo">
                    Sim
                  </label>
                </div>
              </div>
              <div v-if="contrato.reajuste == 1" class="form-group col-sm-5">
                <label
                  >Índice de reajuste: <span class="text-danger">*</span></label
                >
                <select class="form-control" v-model="contrato.indice_reajuste">
                  <option value="">Selecione o tipo índice</option>
                  <option value="0">IPCA</option>
                  <option value="1">INPC</option>
                  <option value="2">IGPM</option>
                </select>
              </div>
              <div v-if="contrato.reajuste == 1" class="form-group col-sm-4">
                <label
                  >Data de reajuste: <span class="text-danger">*</span></label
                >
                <input
                  type="date"
                  class="form-control"
                  placeholder="Data do próximo reajuste"
                  v-model="contrato.data_reajuste"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label
                  >Forma de pagamento: <span class="text-danger">*</span></label
                >
                <select
                  class="form-control"
                  :class="{
                    'is-invalid': $v.contrato.forma_pagamento_id.$error,
                  }"
                  v-model="$v.contrato.forma_pagamento_id.$model"
                >
                  <option value="">Selecione a forma de pagamento</option>
                  <option value="0">Boleto</option>
                  <option value="1">Cheque</option>
                  <option value="2">Dinheiro</option>
                  <option value="3">Cartão de Crédito</option>
                  <option value="4">Cartão de Débito</option>
                  <option value="5">PIX</option>
                  <option value="6">Transferência bancária</option>
                </select>
                <span
                  v-if="!$v.contrato.forma_pagamento_id.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>Status: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  :class="{ 'is-invalid': $v.contrato.status.$error }"
                  v-model="$v.contrato.status.$model"
                >
                  <option value="">Selecione o status</option>
                  <option value="0">Enviado para assinatura</option>
                  <option value="1">Aprovado</option>
                  <option value="2">Vencido</option>
                  <option value="3">Renovado</option>
                  <option value="4">Encerrado</option>
                </select>
                <span
                  v-if="!$v.contrato.status.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Observação:</label>
                <textarea
                  type="text"
                  class="form-control"
                  v-model="contrato.observacao"
                />
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block"
                >Condições de pagamento</span
              >
            </template>
            <div class="form-row" v-if="parcelas.length > 0">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title text-center">Parcelas</h4>
                    <p class="text-center">
                      <span
                        v-if="contrato.cobranca_gerada == 1"
                        class="badge badge-success"
                        >FATURADO {{ contrato.cobranca_gerada }}</span
                      >
                    </p>

                    <p
                      v-if="contrato.cobranca_gerada == 0"
                      class="card-title-desc text-center"
                    >
                      Demonstrativo de pagamento para gerar contas a receber
                    </p>
                    <p
                      v-if="contrato.cobranca_gerada == 1"
                      class="card-title-desc text-center"
                    >
                      Após gerado o faturamento as parcelas não podem ser
                      alteradas
                    </p>
                    <div class="table-responsive">
                      <table class="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th>Parcela</th>
                            <th>Data de vencimento</th>
                            <th>Valor</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(parcela, index) in parcelas" :key="index">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>
                              <input
                                type="date"
                                class="form-control"
                                placeholder="Data da parcela"
                                :name="'parcelas[' + index + '].[vencimento]'"
                                :id="'parcelas[' + index + ']'"
                                v-model="parcelas[index].vencimento"
                                :readonly="contrato.cobranca_gerada == 1"
                              />
                            </td>
                            <td>
                              <div class="input-group">
                                <span
                                  class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                                >
                                  <span class="input-group-text">R$</span></span
                                >
                                <input
                                  type="number"
                                  class="form-control"
                                  placeholder="Valor da parcela"
                                  :name="
                                    'parcelas[' + index + '].[valor_parcela]'
                                  "
                                  :id="'parcelas[' + index + ']'"
                                  v-model="parcelas[index].valor_parcela"
                                  @blur="parcelamento2(index)"
                                  :readonly="
                                    contrato.parcelas_pagamento == 1 ||
                                    contrato.cobranca_gerada == 1
                                  "
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Contas Adicionais</span>
            </template>
            <div>
              <button
                type="button"
                @click="addConta()"
                class="btn btn-primary"
                title="Adicionar conta"
              >
                <i class="bx ri-add-line"></i> Nova conta
              </button>
            </div>
            <div role="tablist">
              <div
                v-for="(conta, index) in contas"
                :key="index"
                class="card task-box"
              >
                <b-card no-body class="mb-1 shadow-none">
                  <b-card-header header-tag="header" role="tab">
                    <div class="form-row">
                      <div class="form-group col-sm-1">
                        <br />
                        <a
                          v-b-toggle="'accordion-' + index"
                          class="btn btn-lg btn-rounded btn-outline-info"
                          href="javascript: void(0);"
                          >{{ index + 1 }}</a
                        >
                      </div>
                      <div class="form-group col-sm-10">
                        <label
                          >Descrição: <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Nome completo"
                          :name="'contas[' + index + '].[descricao]'"
                          :id="'contas[' + index + ']'"
                          v-model="contas[index].descricao"
                        />
                      </div>
                      <div class="form-group col-sm-1">
                        <br />
                        <button
                          type="button"
                          @click="deleteConta(index)"
                          class="btn btn-lg btn-outline-danger"
                          title="Adicionar item"
                        >
                          <i class="bx ri-delete-bin-line"></i>
                        </button>
                      </div>
                    </div>
                  </b-card-header>
                  <b-collapse
                    :id="'accordion-' + index"
                    visible
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <div class="form-row">
                        <div class="form-group col-sm-3">
                          <label
                            >Valor: <span class="text-danger">*</span></label
                          >
                          <div class="input-group">
                            <span
                              class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                            >
                              <span class="input-group-text">R$</span></span
                            >
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Valor da parcela"
                              :name="'contas[' + index + '].[valor_parcela]'"
                              :id="'contas[' + index + ']'"
                              v-model="contas[index].valor"
                              @blur="parcelamentoConta(index)"
                            />
                          </div>
                        </div>
                        <div class="form-group col-sm-3">
                          <label
                            >Condição de Pagamento:
                            <span class="text-danger">*</span></label
                          >
                          <select
                            class="form-control"
                            :name="'contas[' + index + '].[condicao_pagamento]'"
                            :id="'contas[' + index + ']'"
                            v-model="contas[index].condicao_pagamento"
                            @blur="condicaoPagamentoConta(index)"
                          >
                            <option value="">Selecione a condição</option>
                            <option value="1">À Vista</option>
                            <option value="2">Parcelado</option>
                          </select>
                        </div>
                        <div
                          v-if="contas[index].condicao_pagamento == 2"
                          class="form-group col-sm-3"
                        >
                          <label
                            >Nº Parcelas:
                            <span class="text-danger">*</span></label
                          >
                          <input
                            type="number"
                            class="form-control"
                            placeholder="00"
                            :name="'contas[' + index + '].[parcelas]'"
                            :id="'contas[' + index + ']'"
                            v-model="contas[index].parcelas"
                            @blur="parcelamentoConta(index)"
                          />
                        </div>
                        <div
                          class="form-group col-sm-3"
                          v-if="contas[index].condicao_pagamento > 0"
                        >
                          <label v-if="contas[index].condicao_pagamento == 2"
                            >Data da 1ª parcela:
                            <span class="text-danger">*</span></label
                          >
                          <label v-if="contas[index].condicao_pagamento == 1"
                            >Data de vencimento:
                            <span class="text-danger">*</span></label
                          >
                          <input
                            type="date"
                            class="form-control"
                            placeholder="Data de vencimento"
                            :name="'contas[' + index + '].[data_vencimento]'"
                            :id="'contas[' + index + ']'"
                            v-model="contas[index].data_vencimento"
                            @blur="parcelamentoConta(index)"
                          />
                        </div>
                      </div>

                      <div
                        class="form-row"
                        v-if="contas[index].parcelas.length > 0"
                      >
                        <div class="col-lg-12">
                          <div class="card">
                            <div class="card-body">
                              <h4 class="card-title text-center">Parcelas</h4>
                              <p class="text-center">
                                <span
                                  v-if="contrato.cobranca_gerada == 1"
                                  class="badge badge-success"
                                  >FATURADO {{ contrato.cobranca_gerada }}</span
                                >
                              </p>

                              <p
                                v-if="contrato.cobranca_gerada == 0"
                                class="card-title-desc text-center"
                              >
                                Demonstrativo de pagamento para gerar contas a
                                receber
                              </p>
                              <p
                                v-if="contrato.cobranca_gerada == 1"
                                class="card-title-desc text-center"
                              >
                                Após gerado o faturamento as parcelas não podem
                                ser alteradas
                              </p>
                              <div class="table-responsive">
                                <table class="table table-striped mb-0">
                                  <thead>
                                    <tr>
                                      <th>Parcela</th>
                                      <th>Data de vencimento</th>
                                      <th>Valor</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(
                                        parcela, index1
                                      ) in conta.parcelasGeradas"
                                      :key="index1"
                                    >
                                      <th scope="row">{{ index1 + 1 }}</th>
                                      <td>
                                        <input
                                          type="date"
                                          class="form-control"
                                          placeholder="Data da parcela"
                                          :name="
                                            'contas[' +
                                            index +
                                            '][parcelasGeradas][' +
                                            index1 +
                                            '][vencimento]'
                                          "
                                          :id="
                                            'parcelasGeradas[' + index1 + ']'
                                          "
                                          v-model="
                                            contas[index].parcelasGeradas[
                                              index1
                                            ].vencimento
                                          "
                                          :readonly="
                                            contrato.cobranca_gerada == 1
                                          "
                                        />
                                      </td>
                                      <td>
                                        <div class="input-group">
                                          <span
                                            class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                                          >
                                            <span class="input-group-text"
                                              >R$</span
                                            ></span
                                          >
                                          <input
                                            type="number"
                                            class="form-control"
                                            placeholder="Valor da parcela"
                                            :name="
                                              'contas[' +
                                              index +
                                              '][parcelasGeradas][' +
                                              index1 +
                                              '][valor]'
                                            "
                                            :id="
                                              'parcelasGeradas[' + index1 + ']'
                                            "
                                            v-model="
                                              contas[index].parcelasGeradas[
                                                index1
                                              ].valor
                                            "
                                            @blur="parcelamento2(index1)"
                                            :readonly="
                                              contas[index].parcelasGeradas[
                                                index1
                                              ].parcelas_pagamento == 1 ||
                                              contrato.cobranca_gerada == 1
                                            "
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          :title="textModal"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>{{ textModal }}</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import moment from 'moment'

export default {
  name: 'CreateEditContratoModal',

  props: {
    clientId: {
      type: String,
      default: null,
    },
    contratoId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      contrato: {
        client_id: this.clientId,
        titulo: '',
        data_inicio: '',
        data_final: '',
        reajuste: 0,
        indice_reajuste: '',
        data_reajuste: '',
        valor_contrato: '',
        condicao_pagamento: '',
        parcelas_pagamento: '',
        data_vencimento_parcela: '',
        forma_pagamento_id: '',
        cobranca_gerada: '',
        observacao: '',
        status: '',
      },
      parcelas: [],
      contas: [],
      disableButton: false,
    }
  },

  computed: {
    textModal() {
      return this.contratoId ? 'Atualizar' : 'Cadastrar'
    },
  },

  methods: {
    addConta() {
      this.contas.push({
        descricao: '',
        valor: '',
        condicao_pagamento: '',
        parcelas: '',
        data_vencimento: '',
        parcelasGeradas: [],
      })
    },
    deleteConta(index) {
      this.contas.splice(index, 1)
    },
    async formatarValor() {
      const novoValor = this.contrato.valor_contrato.toFixed(2)
      this.contrato.valor_contrato = novoValor
      this.parcelamento()
    },
    async condicaoPagamento() {
      // eslint-disable-next-line eqeqeq
      if (this.contrato.condicao_pagamento == 1) {
        this.contrato.parcelas_pagamento = 1
      }
    },

    async condicaoPagamentoConta(index) {
      // eslint-disable-next-line eqeqeq
      if (this.contas[index].condicao_pagamento == 1) {
        this.$set(this.contas[index], 'parcelas', 1)
      }
    },
    async parcelamento() {
      this.parcelas = []
      if (
        this.contrato.valor_contrato &&
        this.contrato.parcelas_pagamento &&
        this.contrato.data_vencimento_parcela
      ) {
        const parcela =
          this.contrato.valor_contrato / this.contrato.parcelas_pagamento
        const currentDate = new Date(this.contrato.data_vencimento_parcela)
        currentDate.setDate(currentDate.getDate() + 1)
        for (let i = 0; i < this.contrato.parcelas_pagamento; i += 1) {
          this.parcelas.push({
            vencimento: moment(currentDate).format('YYYY-MM-DD'),
            valor_parcela: parcela.toFixed(2),
          })
          currentDate.setMonth(currentDate.getMonth() + 1)
        }
      }
    },
    async parcelamentoConta(index) {
      this.contas[index].parcelasGeradas = []
      if (
        this.contas[index].valor &&
        this.contas[index].parcelas &&
        this.contas[index].data_vencimento
      ) {
        const parcela = this.contas[index].valor / this.contas[index].parcelas
        const currentDate = new Date(this.contas[index].data_vencimento)
        currentDate.setDate(currentDate.getDate() + 1)
        const parcelasContaAux = []
        for (let i = 0; i < this.contas[index].parcelas; i += 1) {
          parcelasContaAux.push({
            vencimento: moment(currentDate).format('YYYY-MM-DD'),
            valor: parcela.toFixed(2),
          })
          currentDate.setMonth(currentDate.getMonth() + 1)
        }
        this.contas[index].parcelasGeradas = parcelasContaAux
      }
    },
    async parcelamento2(index) {
      const novoValor = parseFloat(this.parcelas[index].valor_parcela)
      this.$set(this.parcelas[index], 'valor_parcela', novoValor.toFixed(2))
      let soma = 0
      for (let i = 0; i <= index; i += 1) {
        const valorParcela = parseFloat(this.parcelas[i].valor_parcela)
        soma += valorParcela
      }
      const difValor = this.contrato.valor_contrato - soma
      let parcelasRestantes = this.contrato.parcelas_pagamento - index
      parcelasRestantes -= 1
      const valorParcelaAtualizado = difValor / parcelasRestantes
      for (let i = index + 1; i < this.contrato.parcelas_pagamento; i += 1) {
        this.$set(
          this.parcelas[i],
          'valor_parcela',
          valorParcelaAtualizado.toFixed(2),
        )
      }
    },
    async getContrato() {
      try {
        this.contrato = await this.$store.dispatch('getContrato', {
          clientId: this.clientId,
          contratoId: this.contratoId,
        })
        this.parcelas = await this.$store.dispatch('getParcelasByContrato', {
          clientId: this.clientId,
          contratoId: this.contratoId,
        })
        this.contas = await this.$store.dispatch(
          'getContasAdicionaisByContrato',
          {
            clientId: this.clientId,
            contratoId: this.contratoId,
          },
        )
        this.contrato = { ...this.contrato, value: Number(this.contrato.value) }
        this.$bvModal.show('modalCreateEditContrato')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.contratoId) {
            response = await this.$store.dispatch('updateContrato', {
              client_id: this.clientId,
              contrato_id: this.contratoId,
              contrato: this.contrato,
              parcelas: this.parcelas,
              contas: this.contas,
            })
          } else {
            response = await this.$store.dispatch('saveContrato', {
              client_id: this.clientId,
              contrato: this.contrato,
              parcelas: this.parcelas,
              contas: this.contas,
            })
          }
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    contrato: {
      titulo: {
        required,
      },
      data_inicio: {
        required,
      },
      data_final: {
        required,
      },
      valor_contrato: {
        required,
      },
      condicao_pagamento: {
        required,
      },
      forma_pagamento_id: {
        required,
      },
      data_vencimento_parcela: {
        required,
      },
      status: {
        required,
      },
    },
  },

  mounted() {
    if (this.contratoId) {
      this.getContrato()
    } else {
      this.contrato.cobranca_gerada = 0
      this.$bvModal.show('modalCreateEditContrato')
    }
  },
}
</script>
